<template>
    <div>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <span class="titulo-pagina" v-if="venda_id == null">Selecione o tipo de Certificado desejado:</span>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col class="d-flex justify-center align-center" cols="12" md="6" xl="4"
               v-for="(produto, key) in produtos" :key="key">
          <CardProduto
              :class="{ 'cursor-pointer': true }"
              :chave_cor="key"
              :produto_id="produto.produto_id"
              :nome="produto.descricao"
              :valor_venda="produto.valor_venda"
              :produto_escolhido_id="form.produto_id"
              :disabled="venda_id != null"
              @produtoEscolhido="form.produto_id = $event; produto_selecionado = produto; $refs.form.reset()"
          ></CardProduto>
        </v-col>
      </v-row>

      <div v-show="form.produto_id">
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <span class="titulo-pagina text-center" v-if="venda_id == null">Preencha os dados do seu Certificado <strong>{{produto_selecionado.descricao}}</strong>:</span>
            <span class="titulo-pagina text-center" v-else>Dados do Certificado :</span>
          </v-col>
        </v-row>

        <v-form autocomplete="off" ref="form" class="mx-15">
          <v-row justify="center" class="">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  ref="inputCpfCnpj"
                  v-model="cpf_cnpj_formatado"
                  @change="validaDocumento(form.cpf_cnpj, tipoCertificado, $refs.inputCpfCnpj)"
                  v-mask="tipoCertificado === 'CPF'? '###.###.###-##' : '##.###.###/####-##'"
                  :label="tipoCertificado"
                  :disabled="venda_id != null"
                  outlined dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="form.nome_razao"
                  :disabled="venda_id != null"
                  :label="tipoCertificado === 'CPF'? 'Nome Completo' : 'Razão Social'"
                  outlined dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" v-show="tipoCertificado === 'CNPJ'">
              <v-text-field
                  ref="inputCpfRepLegal"
                  v-model="cpf_rep_formatado"
                  v-mask="'###.###.###-##'"
                  label="CPF Representante Legal"
                  :disabled="venda_id != null"
                  outlined dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" v-show="tipoCertificado === 'CNPJ'">
              <v-text-field
                  v-model="form.nome_replegal"
                  label="Nome Representante Legal"
                  :disabled="venda_id != null"
                  outlined dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="form.celular"
                  label="Celular"
                  outlined dense
                  v-mask="['(##)####-####','(##)#####-####']"
                  :disabled="venda_id != null"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  type="mail"
                  v-model="form.email"
                  label="E-mail"
                  outlined dense
                  autocomplete="new-email"
                  :disabled="venda_id != null"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-mask="['#####-###']"
                  v-model="form.cep"
                  label="CEP"
                  outlined dense
                  ref="inputCep"
                  @change="consultarCep"
                  :disabled="venda_id != null"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="form.cidade"
                  label="Cidade"
                  outlined dense
                  :disabled="venda_id != null"
              ></v-text-field>
            </v-col>


            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="form.logradouro"
                  label="Logradouro"
                  outlined dense
                  :disabled="venda_id != null"
              ></v-text-field>
            </v-col>

            <v-col cols="6" sm="3" md="3">
              <v-text-field
                  v-model="form.numero"
                  label="Numero"
                  outlined dense
                  :disabled="venda_id != null"
              ></v-text-field>
            </v-col>

            <v-col cols="6" sm="3" md="3">
              <v-text-field
                  v-model="form.bairro"
                  label="Bairro"
                  outlined dense
                  :disabled="venda_id != null"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" v-show="tipoCertificado === 'CPF'">
              <v-text-field
                  v-mask="['###############']"
                  v-model="form.cei_caepf"
                  label="CEI/CAEPF"
                  outlined dense
                  hint="Campo não obrigatorio"
                  :disabled="venda_id != null"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  label="Senha do Certificado"
                  outlined dense
                  maxlength="20"
                  autocomplete="new-password"
                  :disabled="venda_id != null"
                  v-model="form.senha"
                  :type="mostrar_senha ?'password' : 'text'"
                  :append-icon="mostrar_senha ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="mostrar_senha = !mostrar_senha"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  label="Confirmar senha do certificado"
                  outlined dense
                  maxlength="20"
                  autocomplete="new-password"
                  :disabled="form.senha === null"
                  v-model="form.confirmacao_senha"
                  :type="mostrar_senha ?'password' : 'text'"
                  :append-icon="mostrar_senha ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="mostrar_senha = !mostrar_senha"
                  :rules="[
                      (mesmaSenha) => !!mesmaSenha || 'Campo confirmação de senha obrigatório',
                      () => this.form.senha === this.form.confirmacao_senha || 'Confirmação de senha é diferente do campo senha'
                  ]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" v-show="form.senha != null" v-if="venda_id == null">
              <div class="mb-5 text-center">
                  <span>
                    <strong>
                      Essa senha é para instalação do seu certificado digital. <br> Anote ela com segurança, a perda resultará na compra de um novo certificado digital.
                    </strong>
                  </span>
              </div>
              <ValidadorNivelSenha
                  :senha="form.senha??''"
                  @senha_aprovada="senha_aprovada = $event"
                  @cor_nivel="cor_nivel_senha = $event"
              ></ValidadorNivelSenha>
            </v-col>
          </v-row>

          <v-row justify="end">
            <v-col class="d-flex justify-end">
              <v-btn color="green" @click="proximoPasso" :dark="liberarBotaoNext" :disabled="!liberarBotaoNext">
                Próximo
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

      </div>
    </div>
  </template>


  <script>

  import CardProduto from "@/views/PaginasExternas/views/VendaRapida/components/CardProduto.vue";
  import ValidadorNivelSenha from "@/components/ValidadorNivelSenha.vue";
  import {mask} from "vue-the-mask";
  import {wsExtSrv} from "@/services/wsServices";
  import {cnpj, cpf} from "cpf-cnpj-validator";
  import {helperServices} from "@/services/helpersServices";
  import {services} from "@/services/PaginasExternasServices";

  export default {
    name: 'FormularioCompra',
    directives: {mask},
    components: {CardProduto, ValidadorNivelSenha},
    props: {
      token: String,
      produtos: Array,
      dados_certificado: [Array, Object],
      venda_id: Number,
      solicitacao_soluti: String,
      tabela_preco_id: Number,
    },
    data: () => ({
      cor_nivel_senha: 'red',
      mostrar_senha: true,
      senha_aprovada: false,
      cpf_cnpj_formatado: '',
      cpf_rep_formatado: '',
      produto_selecionado: {},
      form: {
        produto_id: null,
        cpf_cnpj: null,
        nome_razao: null,
        cpf_replegal: null,
        nome_replegal: null,
        celular: null,
        email: null,
        cep: null,
        cidade: null,
        numero: null,
        bairro: null,
        logradouro: null,
        ibge: null,
        uf: null,
        cei_caepf: null,
        senha: null,
        confirmacao_senha: null
      },
      mostrarErroMesmaSenha: false
    }),
    watch: {
      produto_selecionado(val) {
        window.dataLayer.push({'event': 'loja_produto_selecionado', 'loja_produto_selecionado': val});
      },
      produtos(val) {
        if (val.length === 1) {
          this.produto_selecionado = this.produtos[0]
          this.form.produto_id = this.produto_selecionado.prod_produto_id
        }
      },
      dados_certificado(val) {
        this.cpf_cnpj_formatado = val.cpf_cnpj;
        this.form.nome_razao = val.nome_razao;
        this.form.cpf_replegal = val.cpf_replegal;
        this.form.nome_replegal = val.nome_replegal;
        this.form.celular = val.telefone;
        this.form.email = val.email1;
        this.form.cep = val.cep;
        this.form.cidade = val.cidade;
        this.form.bairro = val.bairro;
        this.form.logradouro = val.logradouro;
        this.form.numero = val.numero;
        this.form.cei_caepf = val.cei_caepf;
        this.form.senha = null;
      },
      cpf_cnpj_formatado(valor) {
        this.form.cpf_cnpj = valor != null ? valor.replace(/[^\d]/g, '') : valor;
      },
      cpf_rep_formatado(valor) {
        this.form.cpf_replegal = valor != null ? valor.replace(/[^\d]/g, '') : valor;
      }
    },
    computed: {
      tipoCertificado() {
        if (this.produto_selecionado.tipo_certificado === "NFE") return "CNPJ";
        if (this.produto_selecionado.tipo_certificado === "BirdID PF") return "CPF";
        if (this.produto_selecionado.tipo_certificado === "A1MEI") return "CNPJ";
        return this.produto_selecionado.tipo_certificado
      },
      liberarBotaoNext() {
        if (this.venda_id != null) return true;
        if (!this.senha_aprovada) return false;
        return !!this.form.confirmacao_senha && this.form.senha === this.form.confirmacao_senha;
      }
    },
    methods: {
      async consultarCep() {
        if (!this.form.cep) return

        var cep = this.form.cep.replace(/[^\d]+/g, '');
        this.$store.commit("setStatus", true);

        await wsExtSrv.viaCep(cep).then(resposta => {

          this.$store.commit("setStatus", false);
          if (resposta.data.erro || !resposta.data.ibge) {
            throw new Error();
          }

          this.form.uf = resposta.data.uf;
          this.form.cep = resposta.data.cep;
          this.form.ibge = resposta.data.ibge;
          this.form.cidade = resposta.data.localidade;
          if (resposta.data.logradouro !== '') {
            this.form.logradouro = resposta.data.logradouro;
          }
          if (resposta.data.bairro !== '') {
            this.form.bairro = resposta.data.bairro;
          }
        }).catch(() => {
          this.form.cep = null;
          this.$refs.inputCep.focus();
          this.$store.commit("setStatus", false);
          this.$notify({type: "warning", title: "Atenção", text: "Cep não encontrado, digite novamente"})
        });
      },

      async validaDocumento(doc, tipo_doc, ref) {
        try {
          if (!doc) return
          this.$store.commit("setStatus", true);
          if (tipo_doc === 'CPF' && !cpf.isValid(doc)) Error("CPF Inválido");
          if (tipo_doc === 'CNPJ' && !cnpj.isValid(doc)) Error("CNPJ Inválido");

          await helperServices.consultaRegistroCpfCnpj(doc).then(resposta => {
            this.form.cep = resposta.data.dados.cep;
            this.form.ibge = resposta.data.dados.cod_mun_ibge;
            this.form.uf = resposta.data.dados.uf;
            this.form.cidade = resposta.data.dados.cidade;
            this.form.cod_mun_ibge = resposta.data.dados.cod_mun_ibge;
            this.form.logradouro = resposta.data.dados.logradouro;
            this.form.bairro = resposta.data.dados.bairro;
            this.form.numero = resposta.data.dados.logradouro_num;
            this.form.nome_razao = resposta.data.dados.nome_razao;
            this.form.email = resposta.data.dados.email1;
            this.form.celular = resposta.data.dados.celular;

            this.consultarCep();
          }).catch(erro => {
            throw new Error(erro.response.data.message)
          })

          this.$store.commit("setStatus", false);
        } catch (Erro) {
          ref.focus()
          this.cpf_cnpj_formatado = null
          this.$store.commit("setStatus", false);
          this.$notify({type: "warning", title: "Atenção", text: Erro.message})
        }
      },

      async proximoPasso() {
        if (this.venda_id == null && this.solicitacao_soluti == null) {
          await this.gerarVenda()
          return;
        }

        if (this.venda_id != null && this.solicitacao_soluti == null) {
          let importado = await this.importarSoluti()
          if (importado) this.$emit("proximoPasso");
        }

        if (this.venda_id != null && this.solicitacao_soluti != null) {
          this.$emit("proximoPasso");
        }
      },

      async gerarVenda() {
        this.$store.commit("setStatus", true);
        var form = {
          token: this.token,
          tabela_preco_id: this.tabela_preco_id,
          prod_produto_id: this.produto_selecionado.prod_produto_id,
          dados_certificado: this.form,
        }

        await services.createNovaVenda(form).then(async (resposta) => {
          this.$store.commit("setStatus", false);
          await this.$emit('setNovaVenda', resposta.data.venda_id);
          window.dataLayer.push({
            'event': 'loja_venda_gerada',
            'loja_venda_gerada': {
              payload: form,
              response: resposta.data
            }
          });
          this.$router.push('/web/venda-rapida/' + resposta.data.token);

        }).catch((err) => {
          this.$store.commit("setStatus", false);
          Object.entries(err.response.data.errors).forEach(([, msg]) => {
            this.$notify({type: "error", text: msg[0]});
          });
        });
      },

      async importarSoluti() {
        this.$store.commit("setStatus", true);
        return await services.importarSoluti({pedido: this.venda_id}).then(async () => {
          await this.novaVendaSoluti()
          return true;
        }).catch((err) => {
          this.$store.commit("setStatus", false);
          this.$notify({type: "error", title: "Falha de importação", text: err.response.data.message});
          return false
        });
      },

      async novaVendaSoluti() {
        this.$store.commit("setStatus", true);
        await services.novaVendaSoluti({pedido: this.venda_id}).then(async () => {
          this.$store.commit("proximoPasso");
          this.$store.commit("setStatus", false);
        }).catch((err) => {
          this.$store.commit("setStatus", false);
          this.$notify({type: "error", title: "Falha de nova venda", text: err.response.data.message});
        });
      }
    }
  }
  </script>
  <style>
  .titulo-pagina {
    font-size: 1.6em;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  </style>
